@charset "utf-8";

.p-company {
    &__msg {
        margin: 2rem 0 5rem 0;
        p {
            margin: 1rem 0;
        }
        .right {
            text-align: right;
        }
    }
}