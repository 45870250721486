@charset "utf-8";

.p-team {
    margin-bottom: 5rem;
    &__contents {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2.5rem;
        row-gap: 4rem;
    }
    &__box {
        &__eyecatch {
            display: flex;
            justify-content: center;
        }
        &__post {
            margin-top: 1rem;
            color: #38457d;
            font-size: 1.5rem;
            line-height: 4.0rem;
            font-weight: bold;
        }
        &__name {
            font-size: 2.5rem;
            font-weight: 700;
            position: relative;
            &::after {
                content: "→";
                position: absolute;
                right: 20px;
            }
        }
    }
    &__detailbox {
        display: grid;
        grid-template-columns: 30% 70%;
        column-gap: 4rem;
        &__rightbox {
            padding-right: 4rem;
        }
        &__sns {
            display: flex;
            justify-content: start;
            gap: 1.5rem;
            margin: 1rem 0;
            background-color: #FFFFFF;
            padding: 1rem 2rem;
        }
        &__contact {
            display: block;
            &__bottom {
                display: none;
            }
        }
        &__msg {
            font-size: 2.5rem;
            font-weight: bold;
            text-align: center;
            margin: 1.5rem 0;
        }
        &__btnbox {
            display: flex;
            justify-content: center;
        }
        &__btn {
            border: none;
            border-radius: 3rem;
            padding: 2rem 10rem;
            background-color: #38457d;
            color: #FFFFFF;
            font-size: 1.5rem;
            cursor: pointer;
        }
        h3 {
            margin-top: 6rem;
            line-height: 5rem;
            font-size: 2.5rem;
            font-weight: bold;
            border-bottom: 1px solid #333333;
        }
        h4 {
            margin-top: 5rem;
            line-height: 4rem;
            font-size: 2.0rem;
            padding-left: 1rem;
            font-weight: bold;
            border-left: 1px solid #333333;
        }
        &__post {
            margin-top: 1rem;
            color: #38457d;
            font-size: 1.5rem;
            line-height: 3.0rem;
            font-weight: bold;
        }
        &__name {
            font-size: 5.0rem;
            font-weight: 700;
            position: relative;
            .yomi {
                color: #a5a5a5;
                font-weight: bold;
                margin-left: 2.0rem;
                font-size: 2.0rem;
            }
        }
        &__company {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 2.5rem;
            margin-top: 5rem;
        }
        &__carrer {
            margin-top: 2rem;
            line-height: 2.5rem;
        }
        &__field {
            margin-top: 2rem;
            line-height: 2.5rem;
        }
    }

    @media screen and (max-width: 960px) {
        &__detailbox {
            &__btn {
                padding: 2rem 6rem;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__detailbox {
            &__rightbox {
                padding-right: 0;
            }
            display: block;
            &__sns {
                margin-bottom: 2rem;
            }
            &__contact {
                display: none;
                &__bottom {
                    display: block;
                    margin-top: 3rem;
                }
            }
            &__btn {
                padding: 2rem 10rem;
            }
            &__name {
                .yomi {
                    margin-left: 0;
                    display: block;
                }
            }
        }
        &__contents {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }
}