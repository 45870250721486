@charset "utf-8";

.p-business{
    &__range {
        &__box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__unordered {
            &__list {
                a {
                    display: block;
                    height: 100%
                }
                &__ttl {
                    padding: 0 !important;
                }
            }
        }
    }
}